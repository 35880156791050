<template>

    <Section :options="sectionOptions">

        <GroupComponent v-for="(group, index) in members" :key="index + '-group'" :options="getGroupOptions(group)" :labels="getGroupLabels(group)"></GroupComponent>
    
    </Section>

</template>

<style>



</style>

<script lang="ts">
import { defineComponent, PropType, watch, computed, onMounted, onBeforeUnmount, ref } from '@fwk-node-modules/vue'
import { getApp, useRouter, useStore } from '@fwk-client/utils/vue-3-migration';
import Section, { SectionOptions } from '@root/src/client/components/panels/layouts/canvas/Section.vue';
import { Group } from '../canvas/team/types';
import { GroupLabels, GroupOptions } from '../canvas/team/Group.vue';
import GroupComponent from '../canvas/team/Group.vue';
import { CmsLabel, CmsText,CmsContent, CmsBoolean, CmsEnum, CmsList } from '@fwk-client/modules/cms/types';

/** @cmsOptions */
export interface BureauOptions {
    /** @cmsType CmsInterface */
    sectionOptions?:SectionOptions
    /** @cmsType CmsLlist */
    members?:CmsList<Group>
}

/** @cmsLabels */
export interface BureauLabels {
    
}

/** @cmsSlots */
export interface BureauSlots {
    
}

export default defineComponent({
    props: {
        options: Object as PropType<BureauOptions>,
        labels: {
          type: Object as PropType<BureauLabels>,
          default: () => { return {} }
        },
        components: Object as PropType<BureauSlots>
    },
    components: {
        Section,
        GroupComponent
    },
    setup(props, context) {

        const app = getApp();
        const $router = useRouter();
        const $store = useStore();
        
        const computedOptions:BureauOptions = {
            sectionOptions : {},
            members : [],
            ...props.options
        };

        const getGroupOptions = (group:Group):GroupOptions => {
            return {
                sectionOptions: {
                    section:false,
                },
                members: group.members
            }
        }

        const getGroupLabels = (group:Group):GroupLabels => {
            return {
                h2:group.title
            }
        }

        

        return {
            members:computedOptions.members,
            sectionOptions:computedOptions.sectionOptions,
            getGroupOptions:getGroupOptions,
            getGroupLabels:getGroupLabels
        }

    }
})
</script>